import first from '../images/1.jpg'
// import second from '../images/2.jpg'
import third from '../images/myteam2.jpg'
import fourth from '../images/myteam1.jpg'
// import fivth from '../images/5.jpg'
// import sixth from '../images/6.jpg'



    const images = [
   //    {
   //     src:sixth,
   //     alt: 'About_Section1'
   //  },
    {
        src:fourth,
        alt: 'About_Section1'
     },
     {
        src:third,
        alt: 'About_Section1'
     },
   //   {
   //      src:second,
   //      alt: 'About_Section1'
   //   },
   //   {
   //      src:fivth,
   //      alt: 'About_Section1'
   //   },
     {
        src:first,
        alt: 'About_Section1'
     },

]


export default images;