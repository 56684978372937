// import React , {useEffect} from 'react'
import Location from "./Location/Location"
import WriteUs from "./WriteUs/WriteUs"

export default function contact() {
  
  return (
    <div>
        <Location/>
        <WriteUs/>
    </div>
  )
}
